import Api, { configFormData } from "@/config/Api";

export default {
  getPayments(options = {}) {
    return Api().get(`/payments`, options);
  },
  getPaymentId(paymentId, options = {}) {
    return Api().get(`/payments/${paymentId}`, options);
  },

  deletePaymentId(paymentId) {
    return Api().delete(`/payments/${paymentId}`);
  },
  updatePaymentId(data) {
    return Api().put(`/payments/${data.id}`, data);
  },

  getInvoicePaymentLink(data = {}) {
    return Api().post("/payment-link/invoices", data);
  },

  getInfoInvoicePay(data) {
    return Api().post(`/u/payment-link/invoices`, data);
  },

  getIdPayLinkInvoices(data) {
    return Api().post(`/pay-gateway/df/pl-invoices/id`, data);
  },

  getPpIdPayLinkInvoices(data) {
    return Api().post(`/pay-gateway/pp/pl-invoices/id`, data);
  },

  sendPaymentReference(data) {
    return Api().post(`/pay-gateway/send-reference`, data);
  },

  addPayLinkUploadDocuments(data) {
    return Api().post(`/payment-link/upload-docs`, data, configFormData);
  }
};
