<template>
  <section class="bar-top-page px-4">
    <div class="row">
      <div class="col-xs-12 col-md-8">
        <section class="bar-top-page-info">
          <div class="d-flex align-items-center">
            <ArrowLeftBack v-if="showBackIcon" />
            <h3 class="h3 text-primary font-semi-bold mt-2">{{ title }}</h3>
          </div>
        </section>
      </div>
      <div class="col-xs-12 col-md-4">
        <section class="bar-top-page-action">
          <slot name="right-area"></slot>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import ArrowLeftBack from "@/components/General/ArrowLeftBack";

export default {
  name: "TopBarTitleComponent",
  components: {
    ArrowLeftBack
  },
  props: {
    title: {
      default: ""
    },
    showBackIcon: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style>
.bar-top-page {
  background: #fff;
  padding: 1rem 0;
  margin-bottom: 2rem;
}
</style>
