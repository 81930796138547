<template>
  <section class="wrapper__business-payment-edit p-4" v-if="payment">
    <b-row>
      <b-col xs="12" lg="12">
        <div
          class="d-flex justify-content-center align-items-center flex-column"
        >
          <h4 class="h4 font-semi-bold mt-3 mb-4 text-center">
            Configuración cobro
          </h4>
        </div>

        <div class="d-flex justify-content-between mt-4">
          <p class="">
            Permitir recibir pagos
            <MoreInfo class="pl-2" content="" />
          </p>
          <p>
            <CustomSwitch
              :setValue="payment.is_link_active"
              @onChange="({ value }) => onChangeSwicth(value, 'is_link_active')"
            />
          </p>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import CustomSwitch from "@/components/General/CustomSwitch";
import MoreInfo from "@/components/General/MoreInfo";
import paymentService from "../services/paymentService";

export default {
  data() {
    return {};
  },
  methods: {
    onChangeSwicth(value, key) {
      this.payment[key] = value;
      this.handleSaveClick();
    },
    async handleSaveClick() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await paymentService
          .updatePaymentId(this.payment)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.$emit("onPaymentEdit", {
            payment: this.payment
          });
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    CustomSwitch,
    MoreInfo
  },
  props: {
    payment: {
      default: null
    }
  }
};
</script>

<style></style>
