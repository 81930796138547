<template>
  <div>
    <div @click.stop="shareLink" class="d-flex w-100">
      <slot></slot>
    </div>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="50%"
      append-to-body
    >
      <div slot="title">
        <h4 class="h4 font-bolder text-color-secondary mb-2">
          Compartir por Whatsapp
        </h4>
        <span class="text-color-black"
          >Recuerda agregar el código del país al número de teléfono
        </span>
      </div>

      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="phone">
          <slot name="label">
            <label class="font-semi-bold m-0 text-color-primary"
              >Número de teléfono</label
            >
          </slot>
          <el-input
            type="text"
            placeholder="Ej. +1234567890"
            class=""
            v-model.trim="form.phone"
          ></el-input>
        </el-form-item>

        <el-form-item prop="text">
          <slot name="label">
            <label class="font-semi-bold m-0 text-color-primary"
              >Texto del mensaje</label
            >
          </slot>
          <el-input
            type="text"
            placeholder=""
            class=""
            v-model="form.text"
          ></el-input>
        </el-form-item>
        <small
          >Personaliza tu mensaje con las variables
          <span class="text-color-primary">#concepto #link #total</span></small
        >
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn md__btn-primary m-2" @click.stop="sendMessage"
          >Enviar</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Validate from "@/assets/validate";

export default {
  name: "ShareLinkWhatsapp",
  data() {
    return {
      dialogVisible: false,
      form: {
        phone: "",
        text: "Hola, te comparto el enlace #link, para completar el pago..."
      },
      rules: {
        phone: [Validate.rules.required()],
        text: [Validate.rules.required()]
      }
    };
  },
  computed: {
    textURI() {
      const { link = "", description = "", total = "" } = this.infoPaymentLink;
      const { phone, text = "" } = this.form;
      const textformat =
        text &&
        text
          .replace("#total", `$${total}`)
          .replace("#concepto", description)
          .replace("#link", link);

      return `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURI(
        textformat
      )}`;
    }
  },
  methods: {
    shareLink() {
      this.dialogVisible = true;
    },
    sendMessage() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          window.open(this.textURI, "_blank");
        }
      });
    }
  },
  props: {
    infoPaymentLink: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped></style>
