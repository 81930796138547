<template>
  <div class="" v-if="payment">
    <TopBarTitleComponent title="Información Cobro" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <el-dropdown
            @command="handleCommand"
            class="dropdown-theme-gray-outline d-flex align-items-center mr-4"
          >
            <span class="el-dropdown-link">
              <span class="ml-2">Opciones</span>
              <i class="el-icon-arrow-down el-icon--right mr-2"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <ShareLinkWhatsapp :infoPaymentLink="payment">
                <el-dropdown-item command="payment-share">
                  Compartir
                </el-dropdown-item>
              </ShareLinkWhatsapp>
              <el-dropdown-item command="payment-delete">
                Eliminar
              </el-dropdown-item>
              <el-dropdown-item command="payment-config">
                Configuración
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4 pt-4">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="5">
          <div
            class="wrapper-sales-invoices-pay md__border border-radius bg-color-write mb-4"
          >
            <h6
              class="h6 text-color-primary text-center my-4 font-bold letter-spacing-4"
            >
              DETALLES DEL COBRO
            </h6>
            <div class="sales-invoices-pay-info mt-4 p-3">
              <b-row>
                <b-col xs="12" sm="12" md="8" lg="8">
                  <small class="text-color-black mb-0">Concepto</small>
                  <h4 class="h4 text-color-primary font-bold mb-4">
                    {{ payment.description }}
                  </h4>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4">
                  <div class="text-right">
                    <h4 class="h4 font-bold text-color-black">Valor</h4>
                    <h3 class="h3 font-bold">
                      <FormatAmount :amount="payment.total" />
                    </h3>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="7">
          <div class="card box-shadow card-quotes-view">
            <div class="card-body">
              <EarningList showTotalEarnings :setEarnings="payment.earnings" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <WrapperDrawer
      :onVisible.sync="drawerPaymentLinkConfig"
      @onCloseDrawer="onCloseDrawerPaymentLinkConfig"
    >
      <PaymentEdit :payment="payment" @onPaymentEdit="onPaymentEdit" />
    </WrapperDrawer>
  </div>
</template>

<script>
import FormatAmount from "@/components/General/FormatAmount";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import ShareLinkWhatsapp from "@/components/General/ShareLinkWhatsapp";
import EarningList from "@/modules/sales/balance/components/EarningList";
import WrapperDrawer from "@/components/General/WrapperDrawer";
import PaymentEdit from "../components/PaymentEdit.vue";

import paymentService from "../services/paymentService";

export default {
  name: "PaymentViewPage",
  data: () => ({
    payment: null,
    drawerPaymentLinkConfig: false
  }),
  computed: {},
  methods: {
    goPage(name) {
      this.$router.push({ name });
    },
    onCloseDrawerPaymentLinkConfig() {
      this.drawerPaymentLinkConfig = false;
    },
    onPaymentEdit({ payment }) {
      this.payment = payment;
    },
    async getPayment(paymentId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await paymentService
          .getPaymentId(paymentId, {
            params: {
              earnings: true
            }
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, data } = response.data;
        if (success) {
          this.payment = data;
        }
      } catch (error) {
        return false;
      }
    },
    async onPaymentDelete() {
      try {
        this.$store.dispatch("toggleRequestLoading");
        const { id: paymentId } = this.payment;
        let response = await paymentService
          .deletePaymentId(paymentId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
          this.goPage("sales.documents");
        }
      } catch (error) {
        return false;
      }
    },
    handleCommand(command) {
      switch (command) {
        case "payment-delete":
          this.onPaymentDelete();
          break;
        case "payment-config":
          this.drawerPaymentLinkConfig = true;
          break;
      }
    }
  },
  components: {
    TopBarTitleComponent,
    FormatAmount,
    ShareLinkWhatsapp,
    EarningList,
    WrapperDrawer,
    PaymentEdit
  },
  mounted() {
    const { id: paymentId } = this.$route.params;
    if (paymentId) {
      this.getPayment(paymentId);
    }
  }
};
</script>

<style lang="scss" scoped></style>
