<template>
  <el-switch
    v-model="value"
    active-color="#3fdbbf"
    inactive-color="#989aae"
    @change="onChange"
  >
  </el-switch>
</template>

<script>
export default {
  name: "CustomSwitch",
  data() {
    return {
      value: this.setValue
    };
  },
  methods: {
    onChange() {
      this.$emit("onChange", { value: this.value });
    }
  },
  watch: {
    setValue(val) {
      this.value = this.$options.filters.formatBoolean(val);
    }
  },
  props: {
    setValue: {
      default: false
    }
  },
  mounted() {
    this.value = this.$options.filters.formatBoolean(this.setValue);
  }
};
</script>

<style></style>
